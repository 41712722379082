// src/utils/api.js
export function getApiBaseUrl() {
    const hostname = window.location.hostname; //localhost:5000 in debugmode
    const protocol = window.location.protocol;
    
    // Split the hostname into parts
    const parts = hostname.split('.');
    
    // If we're already on a subdomain, replace it with 'api'
    // If we're on the main domain, prepend 'api'
    // if (parts.length > 2) {
    //   parts[0] = 'api';
    // } else {
    //   parts.unshift('api');
    // }
    
    const apiHostname = parts.join('.');
    return `${protocol}//${apiHostname}`;
  }
